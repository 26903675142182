<template>
    <div style="padding-top: 8em;">
        <header-service :title="'Konsultasi Hukum'" :subtitle="'Konsultasikan Permasalahan Hukum Anda Bersama Legalpedia'" :text_css="'text-left'"></header-service>
        <section>
            <v-container class="mt-2">
                <v-row>
                    <v-col cols="12" class="mb-0 mt-0">
                        <v-breadcrumbs :items="breadcumbs" class="mb-1 pb-1 breadcrumb">
                            <template v-slot:item="{ item }">
                                <v-breadcrumbs-item
                                    :to="item.href"
                                    class="text-subtitle-2 crumb-item "
                                    :disabled="item.disabled"
                                    exact
                                >
                                    {{ item.text }}
                                </v-breadcrumbs-item>
                            </template>
                        </v-breadcrumbs>
                    </v-col>
                </v-row>
            </v-container>
        </section>
        <section>
            <v-container class="mt-2 mb-12">
                <v-row align="center" justify="center">
                    <div class="col-sm-10">
                        <div class="card border-0">
                            <div class="card-body rounded" style="background:#f5f6f8">
                                <div class="row align-items-center">
                                <div class="col-sm-3">
                                    <img src="@/assets/hukum.png" class="img-fluid">
                                </div>
                                <div class="col-sm-9">
                                    <p class="h2">
                                        Konsultasi Hukum
                                    </p>
                                    <p class="small mb-0 text-secondary">Konsultasikan Permasalahan Hukum Anda hanya dengan menghubungi kami. Legalpedia akan siap melayani permasalahan hukum anda. Aman dan terpercaya</p>
                                    <div class="text-left mt-3">
                                        <a href="https://api.whatsapp.com/send?phone=+6282120938886&text=Hi%20LegalPedia,%20Saya%20ingin%20konsultasi%20tentang%20Hukum.%20Mohon%20Dibantu" target="_blank">
                                            <v-btn color="success" >
                                                Hubungi Kami
                                            </v-btn>
                                        </a>
                                        
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-row>
            </v-container>
        </section>
        <section id="testimoni" class="pt-12 pb-12">
            <v-container class="p-2">
                <v-row>
                    <v-col cols="12">
                        <h3 class="font-open-sans text-dark mb-4 bold text-center">
                        Mengapa Kami ?
                        </h3>
                    </v-col>
                </v-row>
                <v-row class="mt-3 mb-3">
                    <div class="col-sm-4">
                        <div class="card border-0" style="height: 180px;">
                            <div class="card-body rounded" >
                                <div class="row align-items-center">
                                <div class="col-sm-4">
                                    <img src="@/assets/hukum-2.png" class="img-fluid">
                                </div>
                                <div class="col-sm-8">
                                    <p class="bold">
                                        Konsultasi kapan saja dan dimana saja
                                    </p>
                                    <p class="small mb-0 text-secondary">Konsultasikan permasalahan anda dengan kami, kapan saja dan dimana saja anda berada.</p>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="card border-0" style="height: 180px;">
                            <div class="card-body rounded" >
                                <div class="row align-items-center">
                                <div class="col-sm-4">
                                    <img src="@/assets/hukum-3.png" class="img-fluid">
                                </div>
                                <div class="col-sm-8">
                                    <p class="bold">
                                        Kerahasiaan data anda terjamin
                                    </p>
                                    <p class="small mb-0 text-secondary">Data maupun informasi yang anda informasikan kepada kami, dijamin kerahasiaannya.</p>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="card border-0" style="height: 180px;">
                            <div class="card-body rounded" >
                                <div class="row align-items-center">
                                <div class="col-sm-4">
                                    <img src="@/assets/hukum-4.png" class="img-fluid">
                                </div>
                                <div class="col-sm-8">
                                    <p class="bold">
                                        Kompeten dan berpengalaman
                                    </p>
                                    <p class="small mb-0 text-secondary">Dapatkan saran yang yang memuaskan dari tim kami yang kompeten dan berpengalaman</p>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-row>
            </v-container>
            <v-container class="p-2">
                <v-row>
                    <v-col cols="12">
                        <h3 class="font-open-sans text-dark mb-4 bold text-center">
                            Jenis Permasalahan Hukum
                        </h3>
                    </v-col>
                </v-row>
                <v-row class="mt-3 mb-3"> 
                    <div class="col-sm-4">
                        <div class="card border-0" style="height: 180px;">
                            <div class="card-body rounded" >
                                <div class="row align-items-center">
                                <div class="col-sm-4">
                                    <img src="@/assets/hukum-5.png" class="img-fluid">
                                </div>
                                <div class="col-sm-8">
                                    <p class="bold">
                                        Pidana dan Laporan Polisi
                                    </p>
                                    <p class="small mb-0 text-secondary">Investasi illegal, penipuan, penggelapan, pengaduan ke polisi, kekerasan, pencurian, pencemaran nama baik</p>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="card border-0" style="height: 180px;">
                            <div class="card-body rounded" >
                                <div class="row align-items-center">
                                <div class="col-sm-4">
                                    <img src="@/assets/hukum-6.png" class="img-fluid">
                                </div>
                                <div class="col-sm-8">
                                    <p class="bold">
                                        Pertanahan dan Properti
                                    </p>
                                    <p class="small mb-0 text-secondary">Kontrak, jual beli, sewa menyewa, hukum properti, sertipikat, hak atas tanah.</p>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="card border-0" style="height: 180px;">
                            <div class="card-body rounded" >
                                <div class="row align-items-center">
                                <div class="col-sm-4">
                                    <img src="@/assets/hukum-7.png" class="img-fluid">
                                </div>
                                <div class="col-sm-8">
                                    <p class="bold">
                                        Keluarga
                                    </p>
                                    <p class="small mb-0 text-secondary">Waris, perceraian, pembagian harta bersama, perjanjian pisah harta, wasiatah.</p>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-row>
            </v-container>
        </section>
        <contact-us></contact-us>
    </div>
</template>
<script>
import { mapState } from 'vuex'

import ContactUs from "@/components/layout_front/ContactUs.vue"
import HeaderService from "@/components/layout_front/HeaderService.vue"

export default {
components: {
    ContactUs, HeaderService
},
data: () => ({
    data : '',
    panel:[0,1,2,3],
    breadcumbs: [
        {
            text: 'Home',
            disabled: false,
            href: '/',
        },
        {
            text: 'Konsultasi Hukum',
            disabled: true,
        }
    ],
}),
methods:{
    
},
async mounted() { 
},
watch: {
}
}
</script>

<style scoped>

</style>