<template>
    <section class="intro clearfix header_service">
        <div class="container">
            <div class="row">
            <div class="overlay-green"></div>
                <div class="col-md-12 intro-info" :class="text_css ? text_css : 'text-center'" style="z-index: 1;">
                    <h1 class="text-uppercase bold text-white mb-4 text-shadow ls-1">{{  title  }}</h1>
                    <p class="h5 mb-5 text-white text-shadow">{{  subtitle  }}</p>
                    <div>
                    <a href="#start" class="btn btn-light shadow btn-get-started text-uppercase">Start Now</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { mapState } from 'vuex'
  
export default {
    data() {
        return {
            user: ''
        }
    },
    props:['title', 'subtitle', 'text_css'],
    async mounted(){
    },
    methods: {
    }
}
</script>